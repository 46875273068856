import React, { useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../../assets/css/invoice.css";

const DownloadInvoice = ({ order, total, tax, subtotal, setShowInvoice }) => {
  const reportRef = useRef();

  const generatePdf = async () => {
    const element = reportRef.current;
  
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    // const topMargin = 10; // Add a margin at the top of each page
  
    const canvas = await html2canvas(element, { scale: 2 }); // Higher scale for better resolution
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
    let currentHeight = 0;
  
    while (currentHeight < canvas.height) {
      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = canvas.width;
      pageCanvas.height = pageHeight * (canvas.width / pageWidth);
  
      const pageCtx = pageCanvas.getContext("2d");
      pageCtx.drawImage(
        canvas,
        0,
        currentHeight,
        canvas.width,
        pageCanvas.height,
        0,
        0,
        pageCanvas.width,
        pageCanvas.height
      );
  
      const pageImgData = pageCanvas.toDataURL("image/png");
      pdf.addImage(pageImgData, "PNG", 0, 0, imgWidth, pageHeight);
  
      currentHeight += pageCanvas.height;
  
      if (currentHeight < canvas.height) {
        pdf.addPage(); // Add a new page
      }
    }
  
    pdf.save("Invoice.pdf");
  };

  useEffect(() => {
    generatePdf();
    setShowInvoice(false);
  }, []); // Automatically generate the PDF and hide the invoice

  return (
    <div>
      <div ref={reportRef} style={{paddingTop : 20}}>
        <title>Invoice</title>
        <div className="invoice-container">
          <header className="invoice-header">
            <div className="logo">
              <h1>FXRY</h1>
            </div>
            <div className="invoice-number">
              <p>Invoice #{order.custom_order_id}</p>
              <p>
                Issue date<br />
                {new Date(order.createdAt).toDateString()}
              </p>
            </div>
          </header>
          <section className="invoice-details">
            <div className="details-block">
              <p><strong>Customer</strong></p>
              <p>
                {order.customer_details.name}<br />
                {order.customer_details.email}<br />
                {order.customer_details.phone}
              </p>
            </div>
            <div className="details-block">
              <p><strong>Invoice Details</strong></p>
              <p>
                PDF created {new Date(order.createdAt).toDateString()}<br />
                ${total}
              </p>
            </div>
            <div className="details-block">
              <p><strong>Payment</strong></p>
              <p>
                Completed {new Date(order.createdAt).toDateString()}
              </p>
            </div>
          </section>

          <table className="invoice-table">
            <thead>
              <tr>
                <th>Items</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {order.jobs.map((job, index) => (
                <tr key={index}>
                  <td>
                    <strong>{job.item_details.name}</strong><br />
                    {job.service_details.name}
                  </td>
                  <td>1</td>
                  <td>${job.price}.00</td>
                  <td>${job.price}.00</td>
                </tr>
              ))}
            </tbody>
          </table>

          <section className="invoice-summary">
            <p><strong>Subtotal:</strong> ${subtotal}</p>
            <p><strong>Tax:</strong> ${tax}</p>
            <p><strong>Pickup & Drop-off Fee:</strong> ${order.order_type === "Online" ? 5 : 0}</p>
            <p><strong>Total:</strong> ${total}</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DownloadInvoice;