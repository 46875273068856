import React from "react";
import { Link } from "react-router-dom";
import ctaArrow from "../../assets/img/cta-arrow.webp";

function calculateTotalSum(orderObject) {
  let totalSum = 0;
  orderObject?.services?.forEach((service) => {
    let pricing_plan = orderObject?.order_items?.filter((item) => {
      if(item?.id == undefined){
        return item?.name.trim() == service?.items[0]?.name.trim();
      } else {
        return item?.id == service?.item_details?.id
      }
    });
    if (pricing_plan[0]?.pricing_plan == "Premium") {
      totalSum += parseFloat(service.premium_price);
    } else if (service.is_standard) {
      totalSum += parseFloat(service.standard_price);
    }
  });

  return totalSum;
}
function OrderTab({ title, value }) {
  return (
    <div className="order-detail-box">
      <h6>{title}</h6>
      <h4>{value}</h4>
    </div>
  );
}

function getValidCollectionDate(dateString) {
  const restrictedDays = [0, 2, 4, 6]; // Sunday, Tuesday, Thursday, Saturday
  let collectionDate = new Date(dateString);

  // Check the day of the week (0 = Sunday, 6 = Saturday)
  while (restrictedDays.includes(collectionDate.getDay())) {
      // Increment by 1 day if it's a restricted day
      collectionDate.setDate(collectionDate.getDate() + 1);
  }

  return collectionDate;
}

const calculatePickupDateInfo = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getUTCDay();
  let pickupOffset = 0;
  switch (deliveryDay) {
    case 1:
      pickupOffset = 10;
      break;
    case 3:
      pickupOffset = 12;
      break;
    case 5:
      pickupOffset = 10;
      break;
    default:
      pickupOffset = 10;
      break;
  }
  const pickupDate = new Date(deliveryDate);
  while (pickupOffset > 0) {
    pickupDate.setDate(pickupDate.getDate() + 1);
    const dayOfWeek = pickupDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
      pickupOffset--;
    }
  }
  return pickupDate.toUTCString(); // Convert to ISO format for consistency
};

function removeSpecialCharacters(str) {
  const decodedStr = decodeURIComponent(str);
  return decodedStr.replace(/[^\w\s]/gi, '')?.toLowerCase();
}

function Order({ order }) {
  const tobedeliverdate = calculatePickupDateInfo(order?.item?.collection_date);
  let totalSum = calculateTotalSum(order?.item) - order?.item?.total_discount;
  let percentage = 10.35;
  let totalTax = (percentage / 100) * parseFloat(totalSum);
  const { name, itemCount, pickupDate, paymentStatus, orderId, items, item } = order;
  return (
    <li>
      <Link
        to={{
          pathname: `/${removeSpecialCharacters(order?.status)}/${orderId}`,
          state: { name, itemCount, items, order: items, status : order?.status },
        }}
        // onClick={() => {
        //     sessionStorage.setItem('currentOrder', JSON.stringify({ name, itemCount, items, order }));
        // }}
        className="order-box flex-box align-center space-between"
      >
        <OrderTab title="Order Id" value={order?.item?.custom_order_id} />
        <OrderTab title="Customer Name" value={name} />
        {/* <OrderTab title="Order Medium" value={item?.order_type} /> */}
        <OrderTab title="Item Count" value={order?.item?.order_items?.length} />
        <OrderTab
          title="Total Price"
          // order.item.order_type ? :
          value={`$ ${order?.item?.delivery_address_type == "boutique" ? (totalSum + totalTax).toFixed(2) : (totalSum + totalTax + 5).toFixed(2)}`}
        />
        {/* {console.log(order)} */}
        <OrderTab title="Pickup Date" value={order?.pickupDate} />
        <OrderTab title="Delivery Date" value={new Date(tobedeliverdate || order?.item?.deliveryDate).toLocaleDateString("en-US", {
                // weekday: "long",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "America/Los_Angeles",
              })} />
        <div className="order-cta-box">
          <img src={ctaArrow} alt="" />
        </div>
      </Link>
    </li>
  );
}

export default Order;
