import React, { useEffect, useRef, useState } from "react";
import UserDetailBox from "../orders/UserDetailBox";
import OrderItemsListing from "../orders/OrderItemsListing";
import MenuBox from "../common/MenuBox";
import userIcon from "../../assets/img/user-icon.webp";
import emailIcon from "../../assets/img/popup-mail-icon.webp";
import deliveryDetailIcon from "../../assets/img/delivery-detail-icon.webp";

import axios from "axios";
import { useQuery } from "react-query";
import infoIcon from "../../assets/img/info-icon.webp";
import ItemDetail, { calculatePickupDate } from "../orders/ItemDetail";
import dropDownIcon from "../../assets/img/dropdown-icon.webp";
import toast from "react-hot-toast";
import Confirm from "../popup/Confirm";
import RefundForm from "../orders/RefundForm";
import DownloadInvoice from "../common/DownloadInvoice";
function calculateTotalSum(orderObject) {
  let totalSum = 0;
  orderObject?.services?.forEach((service) => {
    let pricing_plan = orderObject?.order_items?.filter((item) => {
      if (item?.id == undefined) {
        return item?.name.trim() == service?.items[0]?.name.trim();
      } else {
        return item?.id == service?.item_details?.id
      }
    });
    if (pricing_plan[0]?.pricing_plan == "Premium") {
      totalSum += parseFloat(service.premium_price);
    } else if (service.is_standard) {
      totalSum += parseFloat(service.standard_price);
    }
  });

  return totalSum;
}

function getValidCollectionDate(dateString) {
  const restrictedDays = [0, 2, 4, 6]; // Sunday, Tuesday, Thursday, Saturday
  let collectionDate = new Date(dateString);

  // Check the day of the week (0 = Sunday, 6 = Saturday)
  while (restrictedDays.includes(collectionDate.getDay())) {
    // Increment by 1 day if it's a restricted day
    collectionDate.setDate(collectionDate.getDate() + 1);
  }

  return collectionDate;
}

const calculatePickupDateInfo = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getUTCDay();
  let pickupOffset = 0;
  switch (deliveryDay) {
    case 1:
      pickupOffset = 10;
      break;
    case 3:
      pickupOffset = 12;
      break;
    case 5:
      pickupOffset = 10;
      break;
    default:
      pickupOffset = 10;
      break;
  }
  const pickupDate = new Date(deliveryDate);
  while (pickupOffset > 0) {
    pickupDate.setDate(pickupDate.getDate() + 1);
    const dayOfWeek = pickupDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
      pickupOffset--;
    }
  }
  return pickupDate.toUTCString(); // Convert to ISO format for consistency
};

function generateOrderId(payload) {
  const item = payload.order_items[0].name.trim().toUpperCase().slice(0, 2);
  const category = payload.services[0].selectedOption
    .trim()
    .toUpperCase()
    .slice(0, 2);
  const repair = payload.services[0].selectedService
    .trim()
    .toUpperCase()
    .slice(0, 2);
  const orderDate = new Date(payload.collection_date)
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, "");
  const firstName = payload.customer_details.name
    .trim()
    .toUpperCase()
    .split(" ")[0]
    .slice(0, 2);
  const orderId = `${item}${category}${repair}${orderDate}_${firstName}`;
  return orderId;
}

function OrderDetailTab({
  name,
  itemCount,
  items,
  order,
  refetch,
  setSelectedInnerTab,
}) {
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const toggleBoxVisibility = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  const [showDetails, setShowDetails] = useState([]);

  const toggleDetails = (index) => {
    setShowDetails((prevShowDetails) => {
      const newShowDetails = [...prevShowDetails];
      newShowDetails[index] = { show: !newShowDetails[index]?.show };
      return newShowDetails;
    });
  };

  const fetchAllFixers = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}fixer/getallfixer`
    );
    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["fetchAllFixers"],
    queryFn: fetchAllFixers,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const [showInvoice, setShowInvoice] = useState(false);
  const [showInvoiceData, setShowInvoiceData] = useState(false);
  const handleDownloadInvoice = async () => {
    const pdfData = await axios.get(`${process.env.REACT_APP_API_URL}users/generateinvoice/${order._id}`);
    if (pdfData.status == 200) {
      setShowInvoice(true);
      setShowInvoiceData(pdfData.data);
    }
    // console.log(pdfData.data);
    // fetch(`${process.env.REACT_APP_API_URL}users/downloadinvoice/${order._id}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "invoice.pdf";
    //     a.click();
    //     URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => console.error(error));
  };
  const total = calculateTotalSum(order) - order?.total_discount;
  let percentage = 10.35;
  let totalTax = (percentage / 100) * parseFloat(total);
  const orderID = generateOrderId(order);
  const tobedeliverdate = calculatePickupDateInfo(order?.collection_date);

  const [showMainPopup, setShowMainPopup] = useState([]);
  const [popupClass, setPopupClass] = useState(
    "popup-box user-edit-popup item-popup"
  );
  const closeMainPopup = (i) => {
    let data = [...showMainPopup];
    data = data.map((ob) => (ob.emailType == i ? { ...ob, show: false } : ob));
    setShowMainPopup(data);
    setPopupClass("popup-box user-edit-popup item-popup");
  };
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowMainPopup([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    if (order?.jobs.some((item) => item.status == "Completed")) {
      setSelectedInnerTab("completed");
    } else {
    }
    if (order?.jobs.some((item) => item.assign_status == "Assigned")) {
      setSelectedInnerTab("in-progress");
    } else {
      setSelectedInnerTab("dashboard");
    }
  }, [order]);

  const [progressStatus, setProgressStatus] = useState(order?.service_status || "Intake");

  const [showpopup, setshowpopup] = useState(false)

  const closePopup = () => {
    setshowpopup(false)
  }

  const statuses = [
    { value: "null", label: "Choose" },
    { value: "intake", label: "Intake" },
    { value: "picked", label: "Picked" },
    { value: "withfixer", label: "With Fixer" },
    { value: "fixedbutwithfixer", label: "Fixed but with Fixer" },
    { value: "readyfordelivery", label: "Ready For Delivery" },
    { value: "fixedandwithfxry", label: "Fixed with FXRY (FXRY)" },
    { value: "delivered", label: "Delivered" },
    { value: "onhold", label: "On-Hold" },
    { value: "archived", label: "Archived" }
  ];

  const shouldDisplay = order?.reason == null || order?.reason === "" || order?.status == "onhold";

  const [isChanged, setIsChanged] = useState(false)

  let selectedStatus = statuses.filter((itm) => itm.value == order?.status)

  const [isSelfNoteEditing, setIsSelfNoteEditing] = useState(false)
  const [selfnotes, setSelfNotes] = useState("")
  const handleUpdateNotes = async () => {
    const resp = await axios.post(`${process.env.REACT_APP_API_URL}order/updateselfnotes/${order?._id}`, {
      notes: selfnotes,
      timing: new Date()
    })
    refetch()
    if (resp.status == 200) {
      toast.success("Updated Successfully!")
      setIsSelfNoteEditing(false)
    }

    if (resp.status == 409) {
      toast.success(resp.data)
    }

  }

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)

  return (
    <>
      {showInvoice &&
          <DownloadInvoice setShowInvoice={setShowInvoice} order={showInvoiceData?.order} total={showInvoiceData?.total} tax={showInvoiceData?.tax} subtotal={showInvoiceData?.subtotal} />
      }
      <div className="tab-detail-outer-box">
        <div className="user-detail-box">
          <div className="head-box flex-box space-between align-center">
            <h6 className="tab-detail-heading f-20">
              {" "}
              <span>
                <img src={userIcon} alt="" />
              </span>{" "}
              Order Details
            </h6>
            <h6 className="user-order-id">
              Order ID:{" "}
              <strong>
                #
                {order?.custom_order_id == undefined ||
                  order?.custom_order_id == null
                  ? orderID
                  : order?.custom_order_id}
              </strong>{" "}
            </h6>
            <h6 className="user-order-id">
              Order Placed At:{" "}
              <strong>
                {new Date(order?.createdAt).toLocaleString("en-US", { day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric", timeZone: "America/Los_Angeles", hour12: true })}
              </strong>{" "}
            </h6>
            <h6 className="user-order-id">
              Order Medium:{" "}
              <strong>
                #
                {order?.order_type || 'Online'}
              </strong>{" "}
            </h6>
          </div>
          <ul className="user-detail-listing flex-box space-between">
            <li>
              <div className="text-box">
                <h6>Name</h6>
                <h4>{order?.customer_details?.name}</h4>
              </div>
              <div className="text-box">
                <h6>Items</h6>
                <h4>{items?.map((itm) => itm?.name + ", ")}</h4>
              </div>
            </li>
            <li>
              <div className="text-box">
                <h6>Service Count</h6>
                <h4>{order?.services?.length}</h4>
              </div>
              <div className="text-box">
                <h6>Email</h6>
                <h4>{order?.customer_details?.email}</h4>
              </div>
            </li>
            <li>
              <div className="text-box">
                <h6>Pick-up Date</h6>
                {/* {console.log(getValidCollectionDate(order?.collection_date))} */}
                <h4>{order?.order_type == "Popup" ? new Date(order?.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }) : new Date(getValidCollectionDate(order?.collection_date)).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })}</h4>
              </div>
              <div className="text-box">
                <h6>Mobile</h6>
                <h4>{order?.customer_details?.phone}</h4>
              </div>
            </li>
            {order?.createdAt && new Date(order.createdAt) >= new Date('2024-10-16') && (
              <li>
                <div className="text-box" style={{ marginTop: "2rem" }}>
                  <h6>Refund Order</h6>
                  <h4>
                    <button
                      className="add-new-btn"
                      style={{ padding: "10px", borderRadius: "10px", cursor: "pointer", border: "white" }}
                      type="button"
                      onClick={() => setIsRefundModalOpen(true)}
                    >
                      Proceed to Refund
                    </button>
                  </h4>
                </div>
              </li>
            )}
            {order?.refund_details?.length > 0 && (
              <li>
                <h6>Refund Details</h6>
                <h4>
                  Total Refund Amount: ${order?.refund_details?.reduce((total, itm) => total + parseFloat(itm.amount), 0)}
                </h4>
                <h4>
                  Latest Refund Status: {order?.refund_details[order?.refund_details?.length - 1]?.refund_status}
                </h4>
                <h4>
                  Refund Type: {order?.refund_details[order?.refund_details?.length - 1]?.refund_type}
                </h4>
                <h4>
                  Scope of Refund: {order?.refund_details[order?.refund_details?.length - 1]?.scope_of_refund}
                </h4>
                <h4>
                  Remarks : {order?.refund_details[order?.refund_details?.length - 1]?.reason}
                </h4>
              </li>
            )}
            <li>
              <div className="text-box relative">
                <h6>Total Order Amount</h6>
                <h4 id="price-info" onClick={toggleBoxVisibility}>
                  {order?.delivery_address_type == "boutique" ? (total + totalTax).toFixed(2) : (total + totalTax + 5).toFixed(2)}
                  <span>
                    <img src={infoIcon} alt="" />
                  </span>
                  <h6>
                    <span
                      className={"green"}
                      style={{
                        marginLeft: "0.8rem",
                        color: "black",
                        fontWeight: "bold",
                        borderRadius: "0.2rem",
                        padding: "0.1rem",
                      }}
                    >
                      Paid
                    </span>
                  </h6>
                </h4>
                <div className={`price-info-box ${isBoxVisible ? "show" : ""}`}>
                  <div className="text-box">
                    <h6>Order Amount</h6>
                    <h4>-</h4>
                    <h6>${total + parseFloat(order?.total_discount)}</h6>
                  </div>
                  <div className="text-box">
                    <h6>10.35 % inclusive tax</h6>
                    <h4>-</h4>
                    <h6>${totalTax.toFixed(2)}</h6>
                  </div>
                  <div className="text-box">
                    <h6>Platform Fee</h6>
                    <h4>-</h4>
                    <h6>${order?.order_type == "Popup" ? 0 : 5}</h6>
                  </div>
                  <div className="text-box">
                    <h6>Applied Discount</h6>
                    <h4>-</h4>
                    <h6>${order?.total_discount}</h6>
                  </div>
                  <div className="text-box">
                    <h6>Service Based Price</h6>
                    <h4>-</h4>
                    <h6>
                      {order?.delivery_address_type == "boutique" ? (total + totalTax).toFixed(2) : (total + totalTax + 5).toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="text-box">
                <h6>Address</h6>
                <h4>{order?.customer_details?.addresses[0]?.line1}, {order?.customer_details?.addresses[0]?.line2}, {order?.customer_details?.addresses[0]?.city} , {order?.customer_details?.addresses[0]?.code}</h4>            </div>
            </li>
            {order?.order_type == "Popup" &&
              <>
                <li style={{ marginTop: "1rem" }}>
                  <div className="text-box">
                    <h6>Popup Location</h6>
                    <h4>{order?.dropofflocation}</h4>
                  </div>
                </li>
              </>
            }
            <li style={{ marginTop: "1rem" }}>
              <div className="text-box">
                <h6>Discount Code</h6>
                <h4>{order?.discount_code || "NA"}</h4>
              </div>
            </li>
            <li style={{ marginTop: "1rem" }}>
              <div className="text-box">
                <h6>How Did You Hear About Us ?</h6>
                <h4>{order?.hear_about_us || "NA"}</h4>
              </div>
            </li>
            <li>
              <div className="field-box">
                <h6>Download Invoice</h6>
                <button
                  className="add-new-btn"
                  style={{ padding: "10px", borderRadius: "10px", cursor: "pointer", border: "white" }}
                  type="button"
                  onClick={() => handleDownloadInvoice()}
                >
                  Click To Download
                </button>
              </div>
            </li>
          </ul>
          <div className="delivery-detail">
            <div className="head-box flex-box space-between align-center">
              <h6 className="tab-detail-heading f-20">
                {" "}
                <span>
                  <img src={deliveryDetailIcon} alt="" />
                </span>{" "}
                Delivery Details
              </h6>
            </div>
            <ul className="user-detail-listing flex-box space-between">
              <li>
                <div className="field-box">
                  <h6>Estimated Delivery Date</h6>
                  <h4>
                    {new Date(tobedeliverdate).toLocaleString("en-US", {
                      day: "numeric",
                      month: "long",
                      weekday: "long",
                      timeZone: "America/Los_Angeles"
                    })}
                  </h4>
                </div>
              </li>
              {order?.delivery_address_type != "boutique" &&
                <>
                  <li>
                    <div className="field-box">
                      <h6>Can we leave the order with a neighbor?</h6>
                      <h4>
                        {order?.delivery_info?.leavewithneighbour == true
                          ? "Yes"
                          : "No" || "Yes"}
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="field-box">
                      <h6>Info 1 (Is there a safe place to leave the delivery ?)</h6>
                      <h4>{order?.delivery_info?.basic_instruction}</h4>
                    </div>
                  </li>
                  <li>
                    <div className="field-box">
                      <h6>Info 2 (Extra delivery instructions)</h6>
                      <h4>{order?.delivery_info?.extra_instruction}</h4>
                    </div>
                  </li>
                </>
              }
              <li>
                <div className="field-box">
                  <h6>Delivery Status</h6>
                  <h4>{order?.email_status?.[order?.email_status?.length - 1]?.emailType}</h4>
                </div>
              </li>
              <li>
                {order.status == "archived" &&
                  <div className="field-box">
                    <h6>Reason Status</h6>
                    <h4>{order?.reason}</h4>
                  </div>
                }
                <div className="field-box job-day-left-box">
                  {shouldDisplay &&
                    <>
                      <h6>MARK AS</h6>
                      {isChanged &&
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setshowpopup(true)}
                        >
                          save
                        </span>
                      }
                      <select
                        // value={order?.status}
                        onChange={(e) => {
                          setIsChanged(true)
                          setProgressStatus(e.target.value)
                        }}
                      >
                        {statuses.map((status) => {
                          if (order?.status !== status.value || order?.status == "intake") {
                            return (
                              <option
                                key={status.value}
                                value={status.value}
                              // selected={order?.status === status.value}
                              >
                                {status.label}
                              </option>
                            )
                          }
                        })}
                      </select>
                      {selectedStatus && selectedStatus.length != 0 &&
                        <h6 style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>{selectedStatus[0]?.label} - {new Date(order?.status_updated_at).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          timeZone: "America/Los_Angeles"
                        })}</h6>
                      }
                    </>
                  }
                </div>
              </li>
              {order.status == "onhold" &&
                <>
                  <li>
                    <div className="field-box">
                      <h6>Reason Status</h6>
                      <h4>{order?.reason}</h4>
                    </div>
                  </li>
                </>
              }
              <li>
                <div className="field-box">
                  <h6>Delivery Address Type</h6>
                  <h4>{order?.delivery_address_type === "boutique" ? "At Boutique" : "Deliver to Me"}</h4>
                </div>
              </li>
            </ul>
          </div>
          {showpopup &&
            <Confirm closeAddPopup={closePopup} type={progressStatus} orderId={order?._id} />
          }
          <div className="order-email-box">
            <div className="text-box">
              <h6>Status & Triggered Emails</h6>
              <div className="container">
                {[
                  "Intake",
                  "Pickup Reminder",
                  "Item Picked",
                  "With Fixer",
                  "Ready For Delivery",
                  "Delivered",
                  // "Contact Support",
                ].map((item, i, arr) => (
                  <React.Fragment key={item}>
                    {item != "Delivered" &&
                      <div
                        className={`item ${order?.email_status?.some((ob) => ob.emailType === item) ? "order-email-sent" : ""}`}
                        onClick={() => {
                          if (
                            !order?.email_status?.some((ob) => ob.emailType === item) ||
                            !order?.email_status
                          ) {
                            if (!showMainPopup[i]) {
                              let data = [...showMainPopup];
                              data.push({ emailType: item, show: true });
                              setShowMainPopup(data);
                            } else {
                              let data = [...showMainPopup];
                              data = data.map((ob) =>
                                ob.emailType === item ? { ...ob, show: true } : ob
                              );
                              setShowMainPopup(data);
                            }
                          }
                        }}
                      >
                        <div>
                          {order?.email_status?.some((ob) => ob.emailType === item && ob.emailType !== "Complete") && (
                            <h6>
                              Sent on :{" "}
                              {new Date(order?.email_status?.find((ob) => ob.emailType === item || ob.emailType === "Complete")?.sendDate).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                timeZone: "America/Los_Angeles"
                              })}
                            </h6>
                          )}
                        </div>
                        <div className="email">
                          <h4>{item}</h4>
                          <span>
                            <img src={emailIcon} alt="" />
                          </span>
                        </div>
                      </div>
                    }
                    {item == "Delivered" &&
                      <React.Fragment key={i + 2}>
                        <div
                          className={`item ${order?.email_status?.some((ob) => ob.emailType === "Complete") ? "order-email-sent" : ""}`}
                          onClick={() => {
                            if (
                              !order?.email_status?.some((ob) => ob.emailType === item) ||
                              !order?.email_status
                            ) {
                              if (!showMainPopup[i]) {
                                let data = [...showMainPopup];
                                data.push({ emailType: item, show: true });
                                setShowMainPopup(data);
                              } else {
                                let data = [...showMainPopup];
                                data = data.map((ob) =>
                                  ob.emailType === item ? { ...ob, show: true } : ob
                                );
                                setShowMainPopup(data);
                              }
                            }
                          }}
                        >
                          <div>
                            {order?.email_status?.some((ob) => ob.emailType === "Complete") && (
                              <h6>
                                Sent on :{" "}
                                {new Date(order?.email_status?.find((ob) => ob.emailType === "Complete")?.sendDate).toLocaleDateString("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  timeZone: "America/Los_Angeles"
                                })}
                              </h6>
                            )}
                          </div>
                          <div className="email">
                            <h4>{item}</h4>
                            <span>
                              <img src={emailIcon} alt="" />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {showMainPopup.find((ob) => ob.emailType === item)?.show && (
                      <div className="popup-outer-box">
                        <div ref={popupRef} className={popupClass}>
                          <div className="confirm-delete-box space-between align-center">
                            <h4>
                              Are you sure you want to send an email? This action cannot
                              be undone.
                            </h4>
                            <div className="yes-no-tab flex-box align-center">
                              <button
                                onClick={async () => {
                                  let data = [...showMainPopup];
                                  data = data.map((ob) =>
                                    ob.emailType === item ? { ...ob, show: false } : ob
                                  );
                                  setShowMainPopup(data);
                                  const response = await axios.post(
                                    `${process.env.REACT_APP_API_URL}order/sendemailstatus/${order._id}`,
                                    {
                                      emailType: item,
                                      sendDate: new Date().getTime(),
                                    }
                                  );
                                  if (response.data) {
                                    refetch();
                                  }
                                }}
                              >
                                Yes
                              </button>
                              <button onClick={() => closeMainPopup(item)}>No</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          <div className="head-box flex-box space-between" style={{ marginTop: "1rem" }}>
            <div className="field-box">
              <h6>Self Order Notes</h6>
              {order?.self_notes == null &&
                <>
                  <textarea maxLength={300} onChange={(e) => setSelfNotes(e.target.value)} rows={8} cols={50}></textarea>
                  <button
                    className="add-new-btn"
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                      border: "white",
                    }}
                    onClick={handleUpdateNotes}
                    type="button"
                  >Save</button>
                </>
              }
              {order?.self_notes != null &&
                <>
                  {isSelfNoteEditing &&
                    <>
                      <textarea maxLength={300} rows={8} cols={50} onChange={(e) => setSelfNotes(e.target.value)} >{order?.self_notes?.notes}</textarea>
                      <h4>Updated At : {new Date(order?.self_notes?.timing).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })}</h4>
                      <button
                        className="add-new-btn"
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          border: "white",
                        }}
                        type="button"
                        onClick={handleUpdateNotes}
                      >Save</button>
                    </>
                  }
                  {!isSelfNoteEditing &&
                    <>
                      <textarea maxLength={300} rows={8} cols={50} disabled value={order?.self_notes?.notes}></textarea>
                      {/* <h2>{order?.self_notes?.notes}</h2> */}
                      <h4>Updated At : {new Date(order?.self_notes?.timing).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })}</h4>
                      <button
                        className="add-new-btn"
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          border: "white",
                        }}
                        type="button"
                        onClick={() => setIsSelfNoteEditing(!isSelfNoteEditing)}
                      >Edit</button>
                    </>
                  }
                </>
              }
            </div>
          </div>
        </div>
        {isRefundModalOpen &&
          <React.Fragment>
            <div className="popup-outer-box">
              <div ref={popupRef} className={popupClass}>
                <RefundForm order={order} previousRefundsAmount={order?.refund_details?.reduce((total, itm) => total + parseFloat(itm.amount), 0)} suborder={order?.jobs} setIsRefundModalOpen={setIsRefundModalOpen} refetch={refetch} totalAmount={order?.delivery_address_type == "boutique" ? (total + totalTax).toFixed(2) : (total + totalTax + 5).toFixed(2)} />
              </div>
            </div>
          </React.Fragment>
        }
        <ul className="items-listing">
          {order?.order_items?.map((ob, index) => {
            return (
              <li className="item-detail-box">
                <div
                  className="head-box flex-box space-between align-center"
                  onClick={() => toggleDetails(index)}
                >
                  <h6 className="f-20">
                    Item - {index + 1} &nbsp;
                    {ob?.name} - {ob?.describe || ob?.description}
                  </h6>{" "}
                  <h6 className="f-20 flex-box align-center">
                    {""}{" "}
                    <span>
                      <img
                        className={` ${showDetails[index]?.show ? "" : "rotate"}`}
                        src={dropDownIcon}
                        alt=""
                      />
                    </span>
                  </h6>
                </div>
                <>
                  {order?.jobs?.map((item, i, arr) => {
                    if (item?.item_details?.id != undefined && ob?.id == item?.item_details?.id) {
                      return (showDetails[index]?.show && (
                        <>
                          <ItemDetail
                            showDetails={showDetails[index]?.show}
                            key={index}
                            count={index}
                            data2={index + 1}
                            data={item}
                            data1={order}
                            tailors={data}
                            refetch={refetch}
                          />
                          {i != arr.length - 1 && (
                            <hr style={{ marginBottom: "20px" }}></hr>
                          )}
                        </>
                      ))
                    } else {
                      if (item?.item_details?.describe == ob?.description && item?.item_details?.id == undefined) {
                        return (
                          <>
                            {showDetails[index]?.show && (
                              <>
                                <ItemDetail
                                  showDetails={showDetails[index]?.show}
                                  key={index}
                                  count={index}
                                  data2={index + 1}
                                  data={item}
                                  data1={order}
                                  tailors={data}
                                  refetch={refetch}
                                />
                                {i != arr.length - 1 && (
                                  <hr style={{ marginBottom: "20px" }}></hr>
                                )}
                              </>
                            )}
                          </>
                        );
                      } else if (item?.item_details?.describe == undefined && item?.item_details?.description == ob?.description && item?.item_details?.id == undefined) {
                        return (
                          <>
                            {showDetails[index]?.show && (
                              <>
                                <ItemDetail
                                  showDetails={showDetails[index]?.show}
                                  key={index}
                                  count={index}
                                  data2={index + 1}
                                  data={item}
                                  data1={order}
                                  tailors={data}
                                  refetch={refetch}
                                />
                                {i != arr.length - 1 && (
                                  <hr style={{ marginBottom: "20px" }}></hr>
                                )}
                              </>
                            )}
                          </>
                        );
                      }
                    }
                  })}
                </>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default OrderDetailTab;
